import { TranslationProvider } from '@local/translations'
import { App } from './'
import { QueryClientProvider, QueryClient } from '@tanstack/react-query'

const queryClient = new QueryClient()

export const AppBootstrap = () => (
  <QueryClientProvider client={queryClient}>
    <TranslationProvider>
      <App />
    </TranslationProvider>
  </QueryClientProvider>
)
