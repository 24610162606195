declare global {
  interface Window {
    TOAST: {
      SSO_LOGIN_LINK: string
      ENVIRONMENT_BASE_URL: string
      TENANT: tenants
    }
  }
}

export enum tenants {
  CUSTOMER = 'CUSTOMER',
  EXTERNAL_DEVELOPER = 'EXTERNAL_DEVELOPER',
  GUEST = 'GUEST'
}

export const getGlobalVariables = () => ({
  ssoLoginLink: window?.TOAST?.SSO_LOGIN_LINK,
  envBaseUrl: window?.TOAST?.ENVIRONMENT_BASE_URL,
  currentTenant: window?.TOAST?.TENANT
})
