import { useEffect, useRef } from 'react'
import { MarketingCarousel } from '@toasttab/buffet-patterns-marketing-carousel'
import { getGlobalVariables, tenants } from '@local/get-global-variables'
import { CustomerLogin } from '@local/customer-login'

export function App() {
  const loginRef = useRef<HTMLDivElement | null>(null)

  useEffect(() => {
    const loginElement = document.querySelector('#login-widget-root')
    if (loginElement && loginRef.current) {
      loginRef.current.appendChild(loginElement)
      loginElement.removeAttribute('style')
    }
  }, [])

  const { currentTenant } = getGlobalVariables()

  return (
    <div className='flex h-screen w-screen'>
      <div data-testid='carousel-container' className='w-3/5 hidden lg:block'>
        <MarketingCarousel />
      </div>
      <div
        data-testid='content-container'
        className='w-full lg:w-2/5 flex flex-col justify-center items-center bg-white'
      >
        <div ref={loginRef} />
        {
          {
            [tenants.CUSTOMER]: <CustomerLogin />,
            [tenants.EXTERNAL_DEVELOPER]: <div data-testId='ext-dev-content' />,
            [tenants.GUEST]: <div data-testId='guest-content' />
          }[currentTenant]
        }
      </div>
    </div>
  )
}
