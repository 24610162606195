import { banquetSingleSpaReact } from 'banquet-runtime-modules'
import { singleSpaCssLifecycles } from '@toasttab/banquet-single-spa-css'
import { AppBootstrap } from './components/App'
import './index.css'

const reactLifecycles = banquetSingleSpaReact({
  singleSpaCssLifecycles,
  cssScope: 'data-tul',
  rootComponent: AppBootstrap,
  portalContainers: ['banquetPortalsContainer'],
  sentry: {
    publicKey: 'a72859977a8ae2d6a058e0786971911e@o37442',
    projectId: '4505912112578560',
    releaseVersion: process.env.SPA_NAMED_VERSION || process.env.PKG_VERSION
  }
})

export const bootstrap = reactLifecycles.bootstrap
export const mount = reactLifecycles.mount
export const unmount = reactLifecycles.unmount
export const name = 'spa-toast-universal-login'
