import { getGlobalVariables } from '@local/get-global-variables'

export const generateResetPasswordURL = () => {
  /**
   * Should be the only link in the login widget.
   * Because of the way the widget is built, there's no other way to get it. :(
   */
  const linkAnchors = [...document.querySelectorAll('main a')]
  const forgotPasswordAnchor = linkAnchors.find((item) =>
    item.getAttribute('href')?.startsWith('/u/reset-password')
  )

  const { envBaseUrl } = getGlobalVariables()

  if (!!forgotPasswordAnchor) {
    /**
     * This template is used for any page during the login flow from Auth0,
     * from credentials to MFA. Meaning that it won't find any node
     * elsewhere than the login page.
     */
    let newLink = `${envBaseUrl}/account/passwordreset`

    /**
     * Update forgot password link to include application query param
     */
    const loginAppRegex = /(loginApplication=\w+);?/i
    const result = document.cookie.match(loginAppRegex)
    if (result) {
      newLink += `?${result[1]}`
    }

    forgotPasswordAnchor.setAttribute('href', newLink)
  }
}
