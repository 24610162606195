import React from 'react'
import { createPortal } from 'react-dom'
import { useTranslation } from '@local/translations'
import { LockLockedIcon } from '@toasttab/buffet-pui-icons'
import { Button } from '@toasttab/buffet-pui-buttons'
import { getGlobalVariables } from '@local/get-global-variables'

export const SsoLoginSection = ({ ssoLoginLink }: { ssoLoginLink: string }) => {
  const { t } = useTranslation()

  return (
    <div className='ulp-alternate-action center'>
      <Button
        as='a'
        variant='link'
        iconLeft={<LockLockedIcon accessibility='decorative' />}
        href={ssoLoginLink}
        className='sso-login-link'
      >
        {t('logInWithSso')}
      </Button>
    </div>
  )
}

export const addSsoLoginSection = () => {
  const loginForm = document.querySelector('form[data-form-primary]')
  const { ssoLoginLink } = getGlobalVariables()

  if (ssoLoginLink && loginForm?.parentElement) {
    return createPortal(
      <SsoLoginSection ssoLoginLink={ssoLoginLink} />,
      loginForm.parentElement
    )
  }
}
