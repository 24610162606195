import { useEffect } from 'react'
import { useTranslation } from '@local/translations'
import {
  generateResetPasswordURL,
  getIsMobileDevice,
  addSsoLoginSection
} from '../utils'

export const CustomerLogin = () => {
  useEffect(() => {
    generateResetPasswordURL()
  }, [])
  const { t } = useTranslation()
  const isMobileDevice = getIsMobileDevice()

  return (
    <>
      {!isMobileDevice && (
        <a
          data-testid='sign-up-link'
          id='sign-up'
          href='https://pos.toasttab.com/request-demo?utm_source=product&amp;utm_medium=login&amp;utm_campaign=demo-request'
          className='text-link font-semibold type-large mt-6'
        >
          {t('notACustomer')}
        </a>
      )}

      {addSsoLoginSection()}
    </>
  )
}
