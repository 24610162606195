import * as React from 'react'
import { cdnTranslationLoaderFactory } from '@toasttab/buffet-pui-translation-utilities'
import {
  useTranslation as buffetUseTranslation,
  TranslationProvider as BuffetTranslationProvider
} from '@toasttab/buffet-pui-translation-utilities'
import defaultStringsEnUS from './defaultStrings-en_US.json'

import { useBanquetProps, useIntlProps } from 'banquet-runtime-modules'

const defaultStrings = defaultStringsEnUS

export type AppStrings = typeof defaultStrings

/**
 * useTranslation - A typed version of useTranslation from Buffet PUI Translation Utilities
 */
export const useTranslation = () => buffetUseTranslation<AppStrings>()

/**
 * TranslationProvider - A typed version of TranslationProvider from Buffet PUI Translation Utilities
 */
export const TranslationProvider = ({
  children
}: {
  children: React.ReactNode
}) => {
  const i18n = useIntlProps()
  const { name: namespace } = useBanquetProps()
  // When available, load this from Banquet
  const cdnUrl =
    '//cdn.toasttab.com/static/34d9bc8b2e7d99006ecfcd8a8ba5e3dc5c75d126/projects/intgs/translations'

  return (
    <BuffetTranslationProvider<AppStrings>
      namespace={namespace}
      i18n={i18n}
      translationLoader={cdnTranslationLoaderFactory<AppStrings>({
        cdnUrl,
        defaultStrings,
        namespace
      })}
    >
      {children}
    </BuffetTranslationProvider>
  )
}
